<template>
  <div class="app-container font-test">
    <!-- 顶部菜单栏和视频区 -->
    <aboutsPageTopMb />
    <div class="content-box">
      <div class="" v-for="(item , id) in contentList" :key="id">
        <el-image :src="item.header[language]" @load="imgOnload">
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <div class="app-download" v-if="showBtn">
          <el-image class="appStore" @click="openUrl('https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553')" :src="appStore" width="100%" lazy></el-image>
          <el-image v-if="language != 'zh'" class="appGoogle" @click="openUrl('https://play.google.com/store/apps/details?id=com.tripellet.app')" :src="appGoogle" width="100%" lazy></el-image>
        </div>
        <div class="">
          <el-image :src="item.main[language]" @load="imgOnload"></el-image>
        </div>
        <div class="app-href" v-if="showBtn">
          <a class="Youtube" :href="item.href[language]" target="_blank">{{ item.href[language] }}</a>
        </div>
        <el-image :src="item.footer[language]" @load="imgOnload"></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import aboutsPageTopMb from '@/components/aboutsPage-top-mb'
import { homeLanList, companyAnno } from '@/utils/public_data'
import { reloadMessage } from "@/lang"
export default {
  data() {
    return {
      activeIndex: '2',
      lanList: homeLanList(),
      contentList: companyAnno(),
      appGoogle: require("@/assets/images/aboutus/app_google.png"),
      appStore: require("@/assets/images/aboutus/app_store.png"),
      showBtn: false,
    }
  },
  components: {
    aboutsPageTopMb
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")]
    },
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  methods: {
    changLan(val) {
      // 设置语言
      this.$i18n.locale = val;
      localStorage.setItem("locale", val);
      reloadMessage();
      this.$router.go(0);
    },
    openUrl(url) {
      window.open(url)
    },
    imgOnload() {
      this.showBtn = true
      this.$forceUpdate()
    }
  }
}
</script>
<style lang='scss' scoped>
.app-download {
  width: 100%;
  display: flex;
  justify-content: center;
}
.app-href {
  width: 99%;
  display: flex;
  justify-content: flex-start;
  padding-left: 7%;
}
.appStore {
  width: 13%;
  cursor: pointer;
}
.appGoogle {
  width: 13%;
  cursor: pointer;
}
.Youtube {
  color: #3883cc;
  // border-bottom: 1px solid #3883cc;
  font-size: 5px;
  background: #fff;
}

.font-test {
  font-family: "GenJyuuGothic";
}
.app-container {
  width: 100%;
  margin: 0 auto;
}
.container {
  width: 100%;
  margin: 0 auto;
}

.content-box {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 20px;
  z-index: 100;
  position: relative;
}
::v-deep .el-menu.el-menu--horizontal {
  border-bottom: 0 !important;
}
</style>