<template>
  <div class="font-test">
    <!-- 顶部菜单栏和视频区 -->
    <aboutsPageTopPc />
    <div class="content-box app-container ">
      <div class="" v-show="['zh'].includes(language)">
        <el-image v-if="showBtn" class="appStore" @click="openUrl('https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553')" :src="appStore" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <el-image v-if="showBtn && language != 'zh'" class="appGoogle" @click="openUrl('https://play.google.com/store/apps/details?id=com.tripellet.app')" :src="appGoogle" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <a v-if="showBtn" class="Youtube" href="https://www.bilibili.com/video/BV16L4y157J9?share_source=copy_web" target="_blank">https://www.bilibili.com/video/BV16L4y157J9?share_source=copy_web</a>
      </div>
      <div class="" v-show="['zh_tw'].includes(language)">
        <el-image v-if="showBtn" style="top:495px;" class="appStore" @click="openUrl('https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553')" :src="appStore" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <el-image v-if="showBtn" style="top:495px;" class="appGoogle" @click="openUrl('https://play.google.com/store/apps/details?id=com.tripellet.app')" :src="appGoogle" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <a v-if="showBtn" style="bottom:272px;left: 74px;" class="Youtube" href="https://youtu.be/B1C2LwUDBCo" target="_blank">https://youtu.be/B1C2LwUDBCo</a>
      </div>
      <div class="" v-show="['en'].includes(language)">
        <el-image v-if="showBtn" style="top:438px;" class="appStore" @click="openUrl('https://apps.apple.com/tw/app/travel-contents-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553')" :src="appStore" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <el-image v-if="showBtn" style="top:438px;" class="appGoogle" @click="openUrl('https://play.google.com/store/apps/details?id=com.tripellet.app')" :src="appGoogle" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <a v-if="showBtn" style="bottom:270px;left: 59px;" class="Youtube" href="https://www.youtube.com/watch?v=3PFiYv4tTDs" target="_blank">https://www.youtube.com/watch?v=3PFiYv4tTDs</a>
      </div>
      <div class="" v-show="['ja'].includes(language)">
        <el-image v-if="showBtn" style="top:492px;" class="appStore" @click="openUrl('https://apps.apple.com/tw/app/travel-contjpts-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553')" :src="appStore" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <el-image v-if="showBtn" style="top:492px;" class="appGoogle" @click="openUrl('https://play.google.com/store/apps/details?id=com.tripellet.app')" :src="appGoogle" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <a v-if="showBtn" style="bottom:270px;left: 75px;" class="Youtube" href="https://www.youtube.com/watch?v=3PFiYv4tTDs" target="_blank">https://www.youtube.com/watch?v=3PFiYv4tTDs</a>
      </div>
      <div class="" v-show="['ko'].includes(language)">
        <el-image v-if="showBtn" style="top:517px;left: 327px;" class="appStore" @click="openUrl('https://apps.apple.com/tw/app/travel-contjpts-%E6%97%85%E9%81%8A%E6%8E%A7/id1597706553')" :src="appStore" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <el-image v-if="showBtn" style="top:517px;left: 489px;" class="appGoogle" @click="openUrl('https://play.google.com/store/apps/details?id=com.tripellet.app')" :src="appGoogle" width="100%" lazy>
          <div slot="placeholder" class="image-slot">
            {{$t('加载中')}}<span class="dot">...</span>
          </div>
        </el-image>
        <a v-if="showBtn" style="bottom:338px;left: 74px;font-size: 15px;" class="Youtube" href="https://youtu.be/HUrUkKvcRiw" target="_blank">https://youtu.be/HUrUkKvcRiw</a>
      </div>
      <el-image :src="contentList[language]" width="100%" lazy @load="imgOnload">
        <div slot="placeholder" class="image-slot">
          {{$t('加载中')}}<span class="dot">...</span>
        </div>
      </el-image>
    </div>
  </div>
</template>

<script>
import aboutsPageTopPc from '@/components/aboutsPage-top-pc'
import { homeLanList, companyAnnoItem } from '@/utils/public_data'
import { reloadMessage } from "@/lang"
export default {
  data() {
    return {
      activeIndex: '2',
      lanList: homeLanList(),
      contentList: companyAnnoItem(),
      appGoogle: require("@/assets/images/aboutus/app_google.png"),
      appStore: require("@/assets/images/aboutus/app_store.png"),
      showBtn: false,
    }
  },
  components: {
    aboutsPageTopPc
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")]
    },
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  methods: {
    changLan(val) {
      // 设置语言
      this.$i18n.locale = val;
      localStorage.setItem("locale", val);
      reloadMessage();
      this.$router.go(0);
    },
    openUrl(url) {
      window.open(url)
    },
    imgOnload() {
      this.showBtn = true
      this.$forceUpdate()
    }
  }
}
</script>
<style lang='scss' scoped>
.appStore {
  position: absolute;
  top: 520px;
  left: 322px;
  width: 153px;
  z-index: 1;
  cursor: pointer;
}
.appGoogle {
  position: absolute;
  top: 520px;
  left: 482px;
  width: 153px;
  z-index: 1;
  cursor: pointer;
}
.Youtube {
  position: absolute;
  color: #3883cc;
  z-index: 1;
  bottom: 288px;
  left: 59px;
  border-bottom: 1px solid #3883cc;
  font-size: 14px;
  background: #fff;
}
.font-test {
  font-family: "GenJyuuGothic";
}
.app-container {
  width: 1000px;
  margin: 0 auto;
}
.container {
  width: 1000px;
  margin: 0 auto;
}

.content-box {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 20px;
  z-index: 100;
  position: relative;
}
::v-deep .el-menu.el-menu--horizontal {
  border-bottom: 0 !important;
}
</style>