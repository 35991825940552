<template>
  <div class="nav-box">
    <div class="top-menu-box" style="width:1000px;margin:0 auto">
      <div class="container top-menu">
        <!-- 左边logo -->
        <div class="left-logo" style="height:27.84px">
          <el-image :src="logo" class="logo-img" lazy @click="pageHandle('/home')"></el-image>
        </div>
        <!-- 右边菜单 -->
        <div class="right-menu">
          <el-menu :default-active="activeIndex" class="bg-t" mode="horizontal">
            <el-submenu index="4">
              <template slot="title">
                <span class="language" style='color: #FF7B00;font-size: 17px;'>
                  Language({{ lan }})
                  <el-image :src="lanLogo" style="width: 20px;line-height: 17px;" lazy />
                </span>
              </template>
              <el-menu-item index="4-1">
                <div class="menu-text2" @click="changLan('ja')">日本語</div>
              </el-menu-item>
              <el-menu-item index="4-2">
                <div class="menu-text2" @click="changLan('zh')">简体中文</div>
              </el-menu-item>
              <el-menu-item index="4-3">
                <div class="menu-text2" @click="changLan('zh_tw')">繁體中文</div>
              </el-menu-item>
              <el-menu-item index="4-4">
                <div class="menu-text2" @click="changLan('en')">English</div>
              </el-menu-item>
              <el-menu-item index="4-5">
                <div class="menu-text2" @click="changLan('ko')">한국어</div>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reloadMessage } from "@/lang"
import { homeLanList } from '@/utils/public_data'
export default {
  data() {
    return {
      logo: require("@/assets/images/customer/PC-home.png"),
      lanLogo: require("@/assets/images/customer/PC_line.png"),
      activeIndex: '2',
      lanList: homeLanList(),
    }
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")]
    },
    language() {
      return localStorage.getItem("locale") || 'zh_tw'
    }
  },
  methods: {
    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } })
      } else {
        this.$router.push(path)
      }
    },
    changLan(val) {
      // 设置语言
      this.$i18n.locale = val;
      localStorage.setItem("locale", val);
      reloadMessage();
      this.$router.go(0);
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-text2 {
  text-align: center;
  overflow: hidden;
  color: #333;
  font-size: 16px;
}
.menu-text2:hover {
  background-color: #ecf5ff;
  color: #66b1ff;
}
.nav-box {
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  background: #fff;
}
.top-menu-box {
  background: #fff;
}
.top-menu {
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo-img {
    width: 30px;
    cursor: pointer;
  }
  .lan-logo {
    width: 21px;
  }
}
.language{
  font-family: "GenSenRoundedTW-M";
}
.bg-t {
  background: rgba(255, 255, 255, 0);
  border-bottom: transparent !important;
}
.el-menu--horizontal .el-menu .el-menu-item {
  padding: 0 !important;
}
::v-deep .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  display: none;
}
::v-deep .el-submenu__title {
  height: 44px !important;
  line-height: 44px !important;
  padding: 0 !important;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: rgba(255, 255, 255, 0);
  // color: #FFBB2C;
}
.el-menu--horizontal > .el-menu-item.is-active {
  // border-bottom: 2px solid #fff;
  color: #ffbb2c;
}
// .el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title,  .el-menu--horizontal .el-menu .el-submenu__title:hover{
//   background-color: rgba(255,255,255,0);
// }
// ::v-deep .el-submenu {
//   background-color: rgba(255,255,255,0);
// }
// :v-deep .el-submenu__title {
//   color: #fff;
//   background-color: rgba(255,255,255,0);
// }
// .el-submenu__title:hover {
//   background-color: rgba(255,255,255,0);
// }
// ::v-deep .el-menu {
//   background-color: rgba(255,255,255,0);
// }
// ::v-deep .el-menu-item {
//   color: #fff;
//   background-color: rgba(255,255,255,0);
//   &:hover, &:focus {
//     background-color: rgba(255,255,255,0);
//   }
// }
::v-deep .el-menu--popup {
  // background-color: rgba(255,255,255,0);
  min-width: 100px;
}
</style>